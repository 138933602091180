* {
  box-sizing: border-box;
}

html {
  font-size: 14px;
  background-color: #edeff1;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
}

body {
  height: 100vh;
}

#root {
  height: 100vh;
  overflow: hidden;
}

circle .p-progress-spinner-circle {
  color: red;
}

@import "primeicons/primeicons.css";
