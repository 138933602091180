/*@import "~primereact/resources/themes/md-light-indigo/theme.css";
@import "~primereact/resources/primereact.min.css";
@import "~primeflex/primeflex.css";
@import "~primeicons/primeicons.css";
*/
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.react-datepicker-wrapper {
  width: -webkit-fill-available;
}

.react-datepicker-wrapper div div div span {
  height: 40px;
}

.MuiFormControl-root {
  height: 40px;
}

.deleteButton {
  position: relative;
  padding: 0px;
  margin-top: -30px;
  top: 20px;
  left: 40px;

  cursor: pointer;
}

.leaflet-container {
  height: 300px;
}

@media all {
  .page-break {
    display: none;
  }
}

.print_citation {
  page-break-inside: avoid;
}

@media print {
  html,
  body {
    margin: 0;
    padding: 0.5rem;
    padding-top: 1.5rem;

    height: initial !important;
    overflow: initial !important;
    -webkit-print-color-adjust: exact;
  }
}

@media print {
  .hide-on-print {
    display: none;
  }
}

@media print {
  .page-break {
    margin-top: 1rem;

    display: block;
    page-break-before: auto;
  }
}

@page {
  size: auto;
  margin: 2mm;
}

.p-dropdown-label {
  white-space: initial;
}
